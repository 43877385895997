//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-416:_7556,_3280,_7000,_428,_7111,_9764,_2052,_2540;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-416')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-416', "_7556,_3280,_7000,_428,_7111,_9764,_2052,_2540");
        }
      }catch (ex) {
        console.error(ex);
      }